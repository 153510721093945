<template>
  <v-container>
    <v-footer inset width="auto" class="primary py-12">
          <v-row justify="center">
              <v-col cols="12" align-self="center">
                <v-card
                  flat
                  tile
                  class="text-center" color="primary"
                >
                  <v-card-text>
                    <v-btn
                      v-for="icon in icons"
                      :key="icon"
                      class="white--text mx-4"
                      icon
                    >
                      <v-icon size="white--text 24px">
                        {{ icon }}
                      </v-icon>
                    </v-btn>
                  </v-card-text>

                  <v-card-text class="white--text pt-0">
                    <div class="text-subtitle-3"><strong class="white--text">Kementerian Kesehatan Republik Indonesia</strong></div>
                    <div class="text-subtitle-2"><strong class="white--text">Jl. H.R. Rasuna Said Blok X.5 Kav. 4-9, Kota Jakarta Selatan, Daerah Khusus Ibu Kota, Jakarta 12950</strong></div>
                    <div class="text-subtitle-2"><strong class="white--text">Telp. 021-5201590 (hunting)</strong></div>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-text class="white--text">
                    {{ new Date().getFullYear() }} — <strong class="white--text">Kemenkes Corporate University</strong>
                  </v-card-text>
                </v-card>
              </v-col>
          </v-row>
  </v-footer>
  </v-container>
</template>

<script>
  export default {
    name: 'FooterCom',

    data: () => ({
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
    }),
  }
</script>