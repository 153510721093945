import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
    {
        name:'home',
        path: '/', 
        component: () => import('@/views/Home')
    },
    {
        name:'bantuan',
        path:'/bantuan', 
        component: () => import('@/views/Help')
    },
    {
        name: 'leaderasteacher',
        path: '/leaderasteacher',
        component: () => import('@/views/Lat')
    },
    {
        name: 'pelatihan',
        path:'/pelatihan',
        component: () => import('@/views/Pelatihan')
    },
    {
        name: 'pengetahuan',
        path:'/pengetahuan',
        component: () => import('@/views/Pengetahuan')
    },
    {
        name: 'pengetahuan_search',
        path:'/cari',
        component: () => import('@/views/PengetahuanSearch')
    },
    {
        name: 'standard',
        path:'/standard',
        component: () => import('@/views/Standar')
    },
    {
        name: 'detail_pengetahuan',
        path:'/detail',
        component: () => import('@/views/PengetahuanDetail')
    },
    {
        name: 'login',
        path: '/login',
        component: () => import('@/views/Login')
    },
    {
        name: 'dashboard',
        path: '/dashboard',
        component: () => import('@/views/Dashboard'),
        meta: {
            header: 1
        }
    },
    {
        name: 'create_post',
        path: '/create',
        component: () => import('@/views/Create'),
        meta: {
            header: 1
        }
    },
    {
        name:'start',
        path: '/start', 
        component: () => import('@/views/Start'),
        meta: {
            header: 666
        }
    },
    // {
    //     name:'notfound',
    //     path:'*', 
    //     component: () => import('@/views/NotFound')
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })
  
export default router