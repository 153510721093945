import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import CKEditor from 'ckeditor4-vue'
import LazyTube from "vue-lazytube";
import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
// import VuePdfApp from "vue-pdf-app";
// import "vue-pdf-app/dist/icons/main.css";
// Vue.component("vue-pdf-app", VuePdfApp);

Vue.use(VueFileAgent,VueFileAgentStyles);
import './scss/main.scss';
Vue.config.productionTip = false
Vue.use(CKEditor)
Vue.use(LazyTube)
new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
