<template>
  <v-app>
    <div class="klcbackground" v-if="$route.meta.header !== 666">
      <v-toolbar color="primary" height="10"></v-toolbar>
      <v-app-bar
      flat
      color="white"
      height="100"
    >
      <Header/>
      <a href="/start" class="hidden-xs-only">
        <v-btn
        elevation="2"
        fab
        color="primary accent-2"
      ><v-icon>mdi-restart</v-icon></v-btn>
      </a>
    </v-app-bar>
    <v-toolbar color="primary" height="10"></v-toolbar>
      <router-view/>
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        bottom
        right
        color="primary"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up-bold</v-icon>
      </v-btn>
    <v-footer color="primary">
      <Footer/>
    </v-footer>
    </div>
    <div v-else>
      <router-view/>
    </div>
  </v-app>
</template>

<script>
import Header from './components/Header';
import Footer from './components/Footer';
export default {
  name: 'App',

  components: {
    Header,
    Footer,
  },

  data: () => ({
    fab: false,
  }),

  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  },
  mounted() {
    document.title = 'Kemenkes Corporate University'
  },
};
</script>
