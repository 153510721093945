<template>
      <v-container>
      <v-toolbar class="flex-grow-0" flat>
        <a href="/">
          <v-img
            class="mx-2"
            src="@/assets/klc-logo.png"
            max-height="100"
            max-width="290"
            contain
          ></v-img>
        </a>
          <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-xs-only">
          <v-btn text class="primary--text">
            <div class="text-h6">Pelatihan</div>
          </v-btn>

          <v-btn text class="primary--text" @click="gotopengetahuan">
            <div class="text-h6">Pengetahuan</div>
          </v-btn>

          <v-btn text class="primary--text">
            <div class="text-h6">Bantuan</div>
          </v-btn>
        </v-toolbar-items>
        <v-btn dense color="warning" class="white--text hidden-xs-only" @click="gotologin" v-if="$route.meta.header !== 1"><div class="text-h6">Masuk</div></v-btn>
        <div class="hidden-sm-and-up">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-app-bar-nav-icon v-on="on" class="primary--text"></v-app-bar-nav-icon>
            </template>
            <v-list class="responsiveMenu" color="primary">
              <v-list-item>
                <v-list-item-title><router-link to="/pengetahuan" class="white--text">Pelatihan</router-link></v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title><router-link to="/pengetahuan" class="white--text">Pengetahuan</router-link></v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title><router-link to="/pengetahuan" class="white--text">Bantuan</router-link></v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-btn dense color="warning" class="white--text" @click="gotologin" v-if="$route.meta.header !== 1">Masuk</v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
    </v-toolbar>
    </v-container>
</template>

<script>
  export default {
    name: 'HeaderCom',

    data: () => ({
      currentUrl: "",
    }),
    watch: {
      group () {
        this.drawer = false
      },
    },
    methods: {
      gotohome(){
          this.$router.push({path:'/'});
          this.currentUrl = window.location.href;
      },
      gotopelatihan(){
          this.$router.push({path:'/pelatihan'});
          this.currentUrl = window.location.href;
      },
      gotopengetahuan(){
          this.$router.push({path:'/pengetahuan'});
          this.currentUrl = window.location.href;
      },
      gotobantuan(){
          this.$router.push({path:'/bantuan'});
          this.currentUrl = window.location.href;
      },
      gotologin(){
          this.$router.push({path:'/login'});
          this.currentUrl = window.location.href;
      },
    },
    created(){
      this.currentUrl = window.location.href;
    }
  }
</script>

<style>
    .v-toolbar__content {
      padding-left: 0px;
    }
    .v-btn {
      text-transform:none !important;
    }
</style>
